body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(0deg, rgba(80, 81, 249, 0.05), rgba(80, 81, 249, 0.05)), #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ===================================================================================================== */
/* For Chrome, Safari, and Opera */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* For Firefox */
/* ::-moz-scrollbar {
    width: 5px;
    height: 5px;
} */

/* For Internet Explorer and Edge */
::-ms-scrollbar {
    width: 5px;
    height: 5px;
}


/* ----------------------------------------------- */
/* Customize the scrollbar track */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: rgb(201, 201, 201);
    border-radius: 5px;
}

/* Customize the scrollbar corner */
::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
}

/* Hide the scrollbar when inactive */
/* ::-webkit-scrollbar-thumb:inactive {
    background-color: transparent;
} */

/* Customize the scrollbar color and width in Firefox */
::-moz-scrollbar {
    scrollbar-color: rgb(202, 202, 202) #f1f1f1;
    scrollbar-width: thin;
}
